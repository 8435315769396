<template>
    <figure class="snip1218" @click="$router.push({name:'bib', params: {id: data._id}})">
      <div class="image"><img :src="apiUrl + data.coverImg" :alt="data.title[getTrad]"/>
      <p v-if="data.desc">
           {{trimWords(ToText(data.desc[getTrad]),20)}}
      </p>
      </div>
      <figcaption>
        <h4>{{trimWords(data.title[getTrad],10)}}</h4>
        <h5 class="category" v-if="data.category">{{data.category.title[getTrad]}}</h5>        
      </figcaption>
    </figure>
</template>
<script>
import { values } from 'lodash';
export default {
  name: 'BookCardBib',
  props: {
      data: {type: Object, default: null},
    },
  data: () => ({
     apiUrl: process.env.VUE_APP_API_URL,
  }),
  computed: {
    getTrad(){
        if(this.data.title.en && this.$i18n.locale == 'en-US') return 'en'
        if(this.data.title.ar && this.$i18n.locale == 'ar-EG') return 'ar'
          return 'fr'
    },
  },
  methods: {
		trimWords(value,number){
      if(!value) return ""
      if(value.split(" ").length < number) return value
			return value.split(" ").splice(0,number).join(" ") + '...';
		},
    ToText(HTML)
    {
        var input = HTML;
        return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');  
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';

figure.snip1218 {
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 260px;
  max-width: 260px;
  width: 100%;
  background: $primary;
  color: #333;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  font-size: 16px;
}
figure.snip1218 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
figure.snip1218 img {
  max-width: 100%;
  height: 362px;
  vertical-align: top;
  object-fit: cover;
}
figure.snip1218 .image {
  position: relative;
  border-bottom: 4px solid $primary;
  z-index: 1;
}
figure.snip1218 .image:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: $primary transparent transparent transparent;
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -15px);
  transform: translate(-50%, -15px);
  z-index: -1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
figure.snip1218 .image p {
  font-weight: 500;
  margin: 0;
  padding: 0 30px;
  line-height: 1.6em;
  position: absolute;
  top: 50%;
  width: 100%;
  color: #ffffff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  font-style: italic;
}
figure.snip1218 figcaption {
  background-color: #ffffff;
  padding: 25px;
  min-height: 200px;
  max-height: 200px;
}
figure.snip1218 h4 {
  margin: 0 0 5px;
  text-transform: uppercase;
  font-weight: 400;
}
figure.snip1218 h4 span {
  font-weight: 800;
}
figure.snip1218 h5 {
  margin: 0 0 15px;
  font-weight: 400;
}
figure.snip1218 i {
  margin-right: 5px;
  display: inline-block;
  font-size: 24px;
  color: $primary;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.05);
}
figure.snip1218 i:hover {
  background-color: $primary;
  color: white !important;
}
figure.snip1218:hover .image:before,
figure.snip1218.hover .image:before {
  border-color: $primary transparent transparent transparent;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}
figure.snip1218:hover .image p,
figure.snip1218.hover .image p {
  opacity: 1;
}
figure.snip1218:hover img,
figure.snip1218.hover img {
  opacity: 0.2;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

figure.snip1218:hover {
   cursor: pointer;
}

.category {
   font-style: italic;
   opacity: 0.5;
}
</style>